import useApi from 'hooks/useApi';
import React, { useRef } from 'react';

 const api=useApi();

 

export class MultiSelect {

    getDevice() {
        return   api.get(process.env.REACT_APP_API_PATH+'devices/index?page=1&limit=1000' ).then(d => d.data);
    }

    getArraySelectDevice(data){
        var arreglo=[];
        data.map((item, key) => {
          arreglo.push({name:item.uuid,value:item.id}); 
          //console.log(item);
            //console.log(key);
        });
        return arreglo;
    }


     getConceptosInfraccion() {
        return   api.get(process.env.REACT_APP_API_PATH+'cataloginfractionitems/index?page=1&limit=1000' ).then(d => d.data);
    }

    getArraySelectConceptosInfraccion(data){
        var arreglo=[];
        data.map((item, key) => {
          arreglo.push({name:item.id+"-"+item.code,value:item.id}); 
          //console.log(item);
            //console.log(key);
        });
        return arreglo;
    }

    getConceptosInfraccionDocumento() {
        return   api.get(process.env.REACT_APP_API_PATH+'CatalogDocumentTypes' ).then(d => d.data);
    }

    getArraySelectConceptosInfraccionDocumento(data){
        var arreglo=[];
        data.map((item, key) => {
          arreglo.push({name:item.id+"-"+item.description,value:item.id}); 
          //console.log(item);
            //console.log(key);
        });
        return arreglo;
    }
    

}